import Button from '@ngin-io/components/public/components/Button/Button.js';
import Link from '../Link';
import useNotifications from '../lib/notifications/useNotifications';

const IndexPage = () => {
  const { notify } = useNotifications();

  return (
    <p>
      <Button
        onClick={() => {
          notify({
            title: 'test',
            description: 'this is just a test',
          });
        }}
      >
        Test notification
      </Button>
      <Button
        variant="secondary"
        onClick={() => {
          notify({
            variant: 'warning',
            title: 'test 2',
            description: 'this is just a test',
          });
        }}
      >
        Test notification
      </Button>
      Main content goes here <Link href="/sign-up">sign up</Link>
    </p>
  );
};

export default IndexPage;
