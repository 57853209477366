// ignore unused exports default

import { NextSeo } from 'next-seo';
import { Fragment } from 'react';
import IndexPage from '../components/pages/IndexPage';

const Index = () => {
  return (
    <Fragment>
      <NextSeo />
      <IndexPage />
    </Fragment>
  );
};

export default Index;
